import { ResourceLanguage } from 'i18next';

import { apiTranslation } from './api';
import { authTranslation } from './auth';
import { formTranslation } from './form';
import { termsTranslation } from './terms';
import { feedbackTranslation } from './feedback';
import { homeTranslation } from './home';
import { headerTranslation } from './header';
import { aboutUsTranslation } from './about-us';
import { casesTranslation } from './cases';

const translation: ResourceLanguage = {
  company: 'Company',
  candidate: 'Candidate',
  job: 'Job',
  auth: authTranslation,
  form: formTranslation,
  terms: termsTranslation,
  feedback: feedbackTranslation,
  api: apiTranslation,
  home: homeTranslation,
  header: headerTranslation,
  aboutUs: aboutUsTranslation,
  cases: casesTranslation,
};

export default translation;
