const ROOTS = {
  AUTH: '/auth',
};

export const paths = {
  root: '/',
  auth: {
    root: ROOTS.AUTH,
    login: `${ROOTS.AUTH}/login`,
  },

  contactUs: {
    root: '/contact',
  },

  cases: {
    root: '/cases',
    crm: '/cases/crm',
    agyx: '/cases/agyx',
    centernorte: '/cases/centernorte',
    cattalini: '/cases/cattalini',
    urbia: '/cases/urbia',
  },

  // PAGES
  home: {
    root: '/home',
  },

  aboutUs: {
    root: '/about',
  },

  example: {
    root: '/example',
  },

  testAuthAndGuard: {
    root: '/testAuthAndGuard',
  },

  testRepository: {
    root: '/testRepository',
  },

  redirect: {
    root: '/redirect',
    bienal: '/redirect/bienal',
  },

  error: {
    notFound: '/404',
    forbidden: '/403',
    serverError: '/500',
  },
}
