export const aboutUsTranslation = {
  title: 'Sobre Nós',
  content: `<p>
        Somos uma Software House especializada em pesquisa, concepção, desenvolvimento e implementação de produtos tecnológicos voltados para diversos segmentos. Nosso compromisso é entregar soluções inovadoras de forma ágil, personalizada e estratégica, unindo design e engenharia para proporcionar experiências excepcionais aos nossos clientes.
    </p>
    <p>
        Criamos produtos de tecnologia, como:
    </p> 
    <ul>
        <li>
            <strong>Novos Apps;</strong> 
        </li>
        <li>
            <strong>Plataformas Web;</strong>
        </li>
        <li>
            <strong>Dispositivos Eletrônicos (IoT);</strong>
        </li>
        <li>
            <strong>Produtos com base em Inteligência Artificial.</strong>
        </li>
    </ul>
    <p>
        Se você tem uma ideia ou quer resolver um problema, marca uma reunião com a gente!
    </p>`,
  button: 'Fale conosco',
};
