import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { m } from 'framer-motion';
import { Stack, Typography } from '@mui/material';
import { logCustomEvent } from 'src/utils/analytics';
import { useContactUsAction } from './contact-us-action.hook';

export interface ContactUsActionProps {}

export default function ContactUsAction(Props: ContactUsActionProps) {
  const { isHovered, setIsHovered, navigate, t, valuesFromHover } = useContactUsAction();

  return (
    <Stack
      onClick={() => {
        navigate('/contact');
        logCustomEvent('contact_us_header_button', {
          label: 'Contact Us Header Action',
          description: 'Mail icon in header, which navigates to /contact  ',
        });
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      direction="row"
      spacing={1}
      sx={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'center' }}
    >
      <m.span
        animate={{
          x: valuesFromHover({ from: -2, to: 0 }),
        }}
        transition={{
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.5,
        }}
      >
        <MailOutlineIcon sx={{ color: '#fff', mt: 1 }} />
      </m.span>

      <m.div
        initial={{ width: 0, opacity: 0 }}
        animate={{
          width: isHovered ? 'auto' : 0,
          opacity: isHovered ? 1 : 0,
        }}
        transition={{
          type: 'tween',
          ease: 'easeInOut',
          duration: 0.4,
        }}
        style={{
          overflow: 'hidden',
          display: 'flex',
          transformOrigin: 'left',
          whiteSpace: 'nowrap',
        }}
      >
        <Typography color="#fff" fontSize={12} fontFamily="Poppins">
          {t('contact_us')}
        </Typography>
      </m.div>
    </Stack>
  );
}
