export const homeTranslation = {
  title: 'CREATE. TRANSFORM.',
  description:
    'We were born to create. When we create, we transform; and when we transform, we see the world from new perspectives. From simple things to the most complex; from ideas to buildings, from dreams to realities.',
  subdescription: "Cool! But what's done at Rox...",
  cases: {
    cattalini: 'Largest liquid terminal in Brazil',
    cn: 'One of the largest shopping malls in Latin America',
    crm: 'Rewards and shopping program',
    agyx: 'Air conditioning service provider',
    urbia: 'Territorial feasibility studies',
  },
};
