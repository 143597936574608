export const formTranslation = {
  titles: {
    create_company: 'Creating Your Company',
    create_job: 'New Job',
    company_data: 'Company Data',
    contacts: 'Contacts',
    localization: 'Location',
    tips: 'Tips',
    details: 'Details',
    properties: 'Properties',
    job_questions: 'Job Questions',
    job_filters: 'Job Filters',
    create_professional_profile: 'Creating Your Professional Profile',
    edit_professional_profile: 'Editing Your Professional Profile',
    personal_data: 'Personal Data',
    professional_data: 'Professional Data',
    preferences: 'Preferences',
    schedule: 'Work Schedule',
    salary: 'Salary',
    salary_negotiable: 'Negotiable Salary',
    benefits: 'Benefits',
  },
  fields: {
    select: 'select',
    email: 'Email',
    password: 'Password',
    confirm_password: 'Confirm Password',
    name: 'First Name',
    lastname: 'Last Name',
    company_name: 'Company Name',
    trading_name: 'Trade Name',
    about: 'About',
    history: 'History',
    segment: 'Industry',
    employees: 'Employees',
    phone: 'Phone',
    website: 'Website',
    country: 'Country',
    estate: 'State',
    city: 'City',
    title: 'Title',
    description: 'Description',
    type: 'Type',
    experience: 'Experience',
    role: 'Role',
    cpf: 'CPF',
    curriculum: 'Resume',
    experiences: 'Experiences',
    interest_area: 'Interest Area',
    skills: 'Skills',
    tech_skills: 'Technical Skills',
    behavior_skills: 'Behavioral Skills',
    presential: 'On-site',
    presential_days: 'On-site Days',
    hybrid: 'Hybrid',
    remote: 'Remote',
    work_regime: 'Work Regime',
    full_time: 'Full-time',
    part_time: 'Part-time',
    freelancer: 'Freelancer',
    trainee: 'Intern',
    no_experience: 'No experience',
    '1_year_exp': '1 year',
    '2_year_exp': '2 years',
    less_3_year_exp: 'More than 3 years',
    not_applicable: 'Not applicable',
    black_people: 'Black People',
    women: 'Women',
    pcd: 'People with Disabilities (PwD)',
    lgbtqiap: 'LGBTQIAP+',
    indigenous: 'Indigenous People',
    job_affirmative: 'Inclusive Job',
    job_limit: 'Candidate Limit',
    interview_steps: 'Required Interview Steps',
    start_date: 'Start Date',
    end_date: 'End Date',
    job_allow_questions: 'Allow candidates to ask questions',
    job_allow_less_skills: 'Allow candidates who do not meet 100% of the required skills',
  },
  actions: {
    save: 'Save',
    next: 'Next',
    enter: 'Enter',
    register: 'Create Account',
    publish: 'Publish',
    add_question: 'Add Question',
    add: 'Add',
    download_file: 'Download File',
  },
  messages: {
    required: '{{field}} is required',
    forgot_password: 'Forgot your password?',
    email_valid: 'Email must be a valid email address',
    choose_at_least_one_option: 'Select at least one option',
    phone_valid: 'Incomplete phone number',
  },
  errors: {
    invalid_data: 'Invalid data',
    auth_unknown: 'Authentication failed. Check your details or contact support',
    unknonw_error: 'Unknown error',
  },
  contact_us: {
    title: 'CONTACT US',
    name: 'Name',
    email: 'Email',
    more_info: 'More Information',
    send: 'Send',
    name_error: 'Enter your name.',
    email_error: 'Enter your email.',
    email_valid_error: 'Enter a valid email.',
    more_info_error: 'Enter more contact information.',
  },
};
