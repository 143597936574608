import { getAnalytics, logEvent, setAnalyticsCollectionEnabled } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';

export const firebaseConfig = {
  apiKey: 'AIzaSyDKSL4Syia66zthlrA-yMgV4ae5tA1AtZE',
  authDomain: 'roxcode-site.firebaseapp.com',
  projectId: 'roxcode-site',
  storageBucket: 'roxcode-site.appspot.com',
  messagingSenderId: '1004546224429',
  appId: '1:1004546224429:web:b5f29354463c4dc66723a4',
  measurementId: 'G-HCNJP3J33X',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics();
setAnalyticsCollectionEnabled(analytics, true);

export const logPageView = (pageName: any, params = {}) => {
  const pageNameParts = pageName.split('/');
  const eventName = `${pageNameParts[pageNameParts.length - 1]}_page_view`;
  logEvent(analytics, eventName, params);
};

export const logCustomEvent = (eventName: any, params = {}) => {
  logEvent(analytics, eventName, params);
};
