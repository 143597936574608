export const aboutUsTranslation = {
  title: 'About Us',
  content: `<p>
        We are a Software House specialized in research, design, development, and implementation of technological products aimed at various segments. Our commitment is to deliver innovative solutions in an agile, personalized, and strategic manner, combining design and engineering to provide exceptional experiences for our clients.
    </p>
    <p>
        We create technology products such as:
    </p> 
    <ul>
        <li>
            <strong>New Apps;</strong> 
        </li>
        <li>
            <strong>Web Platforms;</strong>
        </li>
        <li>
            <strong>Electronic Devices (IoT);</strong>
        </li>
        <li>
            <strong>Products based on Artificial Intelligence.</strong>
        </li>
    </ul>
    <p>
        If you have an idea or want to solve a problem, schedule a meeting with us!
    </p>`,
  button: 'Contact Us',
};
