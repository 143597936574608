import LanguageIcon from '@mui/icons-material/Language';
import { m } from 'framer-motion';
import { Stack, Typography, Menu, MenuItem, IconButton } from '@mui/material';
import Iconify from 'src/components/iconify';
import { logCustomEvent } from 'src/utils/analytics';
import { useLanguageMenu } from './language-menu.hook';

export default function LanguageMenu() {
  const {
    isLangHovered,
    setIsLangHovered,
    anchorEl,
    open,
    t,
    i18n,
    handleMenuOpen,
    handleMenuClose,
    changeLanguage,
    languages,
  } = useLanguageMenu();

  return (
    <Stack
      onMouseEnter={() => setIsLangHovered(true)}
      onMouseLeave={() => {
        if (!open) setIsLangHovered(false);
      }}
      direction="row"
      alignItems="center"
      sx={{ cursor: 'pointer', position: 'relative' }}
    >
      <m.div
        initial={{ width: 0, opacity: 0, marginRight: 0 }}
        animate={{
          width: isLangHovered ? 'auto' : 0,
          opacity: isLangHovered ? 1 : 0,
          marginRight: isLangHovered ? 8 : 0,
        }}
        transition={{ type: 'tween', ease: 'easeInOut', duration: 0.2 }}
        style={{
          overflow: 'hidden',
          display: 'flex',
          whiteSpace: 'nowrap',
          position: 'absolute',
          right: '100%',
        }}
      >
        <Typography color="#fff" fontSize={12} fontFamily="Poppins">
          {t('language')}
        </Typography>
      </m.div>
      <IconButton
        onClick={(event) => {
          handleMenuOpen(event);
          logCustomEvent('language_menu_open', {
            label: 'Language Menu Header Icon',
            description: 'Clickable Language Menu Header Icon',
          });
        }}
        sx={{ color: '#fff' }}
      >
        <LanguageIcon sx={{ color: '#fff' }} />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        sx={{
          mt: 4,
          '& .MuiPaper-root': { backgroundColor: '#1c1521' },
        }}
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            onClick={() => changeLanguage(lang.code)}
            sx={{ backgroundColor: i18n.language === lang.code ? '#291f30' : 'inherit' }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Iconify icon={lang.flag} />
              {lang.label}
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
}
