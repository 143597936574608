export const apiTranslation = {
  errors: {
    Unauthorized: 'Unauthorized',
    'password must have uppercase letters, lowercase letters and numbers':
      'Your password must contain uppercase letters, lowercase letters, and numbers',
    'password and confirm password must match': 'Password and Confirm Password must match',
    'password must be longer than or equal to 8 characters':
      'Password must be at least 8 characters long',
    'password must be shorter than or equal to 32 characters':
      'Password must be at most 32 characters long',
    'title should not be empty': 'Title cannot be empty',
    'description should not be empty': 'Description cannot be empty',
    'kind must contain at least 1 elements': 'Kind must have at least one selected option',
    'role should not be empty': 'Role cannot be empty',
    'tech_skills must contain at least 1 elements':
      'Technical skills must have at least one selected option',
    'behavior_skills must contain at least 1 elements':
      'Behavioral skills must have at least one selected option',
    "Duplicate unique key 'cpf'": 'The provided CPF is already registered for another candidate',
  },
};
