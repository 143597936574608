export const casesTranslation = {
  downloadIn: 'Baixar em',
  cattalini: {
    initialTexts: [
      { title: 'Segmento', content: 'Indústria' },
      { title: 'O que fizemos', content: 'Discovery, criação de App e IA.' },
    ],
    finalTexts: [
      {
        title: 'Empresa',
        content:
          'A Cattalini é o maior terminal de líquidos do Brasil, localizada em Paranaguá/PR. Conta com 133 tanques e capacidade para armazenar mais de 610.000 metros cúbicos de granéis líquidos. Uma empresa que está em constante busca por tecnologia e segurança nos serviços de granéis líquidos.',
      },
      {
        title: 'Desafio',
        content:
          'A Roxcode está atualmente desenvolvendo a 3ª onda do aplicativo Cattalini, a fim de facilitar o acesso a informações importantes. A solução ajuda clientes no acompanhamento de suas cargas, atendimento e agendamentos. Também auxilia transportadoras e motoristas no processo de carga e descarga, entre muitas outras funcionalidades.',
      },
    ],
  },
  centerNorte: {
    initialTexts: [
      { title: 'Segmento', content: 'Varejo' },
      {
        title: 'O que fizemos',
        content: 'Criação de App e inteligência e negócio',
      },
    ],
    finalTexts: [
      {
        title: 'O shopping',
        content: `O Shopping Center Norte é um ícone da Zona Norte de São Paulo e um dos maiores destinos de compras e lazer da região. Localizado em uma área estratégica ao lado da Marginal Tietê, ele integra a Cidade Center Norte — um complexo que reúne shopping centers, hotel, espaço de eventos e o Lar Center. Desde sua inauguração, o Center Norte continua a inovar para oferecer experiências únicas aos seus visitantes.`,
      },
      {
        title: 'O desafio',
        content: `O Shopping Center Norte buscava uma solução tecnológica para fortalecer o relacionamento com seus clientes, incentivando o consumo nas lojas participantes e recompensando a fidelidade. O desafio era criar um clube de benefícios que fosse intuitivo, dinâmico e conectado ao perfil dos consumidores do shopping.`,
      },
      {
        title: 'Nossa solução',
        // @ts-ignore
        content: `
          <p>
            A Roxcode desenvolveu o VIVA Center Norte, um aplicativo exclusivo que funciona como o clube de benefícios oficial do shopping. Entre as principais funcionalidades entregues, estão:
          </p>
          <ul>
            <li>
                <strong>Sistema de Pontuação:</strong> Os clientes acumulam pontos automaticamente em cada compra realizada nas lojas participantes.
            </li>
            <li>
                <strong>Troca de Pontos por Benefícios:</strong> Os pontos podem ser resgatados por recompensas, como descontos exclusivos, brindes e experiências personalizadas.
            </li>
            <li>
                <strong>Notificações Personalizadas:</strong> Comunicação em tempo real sobre promoções e eventos.
            </li>
            <li>
                <strong>Integração Omnichannel:</strong> Conexão entre compras físicas e digitais, garantindo uma experiência fluida para os usuários.
            </li>
          </ul>
          <p>
              O VIVA Center Norte foi projetado para melhorar a experiência dos clientes, aumentar o engajamento e trazer novas oportunidades de negócios para o shopping e suas lojas parceiras.
          </p>
        `,
      },
    ],
  },
  crm: {
    initialTexts: [
      { title: 'Segmento', content: 'Varejo' },
      {
        title: 'O que fizemos',
        content: 'Criação de App e inteligência tecnológica',
      },
    ],
    finalTexts: [
      {
        title: 'Sobre a CRM&Bonus',
        content: `A CRM&Bonus é uma plataforma inovadora que utiliza dados e inteligência artificial para transformar as relações entre empresas e seus clientes. Criada em 2018 com o lançamento do Giftback, a empresa rapidamente se destacou no mercado de soluções de retenção e fidelidade. Hoje, a CRMBonus oferece um portfólio robusto de ferramentas que incluem o Vale Bonus, CRM Ads e o CRM360, ajudando empresas de todos os setores e tamanhos a atrair, engajar e fidelizar consumidores.
            Com escritórios em São Paulo e Joinville, a CRMBonus reúne mais de 350 especialistas e já levantou R$ 280 milhões em venture capital, contando com o apoio de investidores renomados como Softbank e Riverwood. A empresa é responsável por gerar mais de R$ 5 bilhões em vendas incrementais e bonificar mais de 100 milhões de consumidores em 2023.`,
      },
      {
        title: 'Nossa parceria',
        content: `A Roxcode tem orgulho de ser parceira estratégica da CRM&Bonus, atuando diretamente na evolução do aplicativo Vale Bonus. Nosso time colabora com a equipe interna da CRM&Bonus para otimizar a performance do app, melhorar a experiência do usuário e integrar novas funcionalidades alinhadas às demandas do mercado.
            Por meio dessa parceria, o aplicativo Vale Bonus dobrou sua base de usuários ativos e registrou um aumento significativo na adesão dos clientes às campanhas de fidelidade. Os resultados demonstram o impacto positivo da tecnologia desenvolvida pela Roxcode e o compromisso com a excelência.`,
      },
    ],
  },
  agyx: {
    initialTexts: [
      { title: 'Segmento', content: 'Varejo e serviço' },
      {
        title: 'O que fizemos',
        content: 'Desenvolvimento de app e backoffice',
      },
    ],
    finalTexts: [
      {
        title: 'A Frigelar',
        content: `A Frigelar é líder no Brasil nos segmentos de refrigeração, 
            climatização e eletros fornecendo soluções completas aos seus clientes há mais 
            de 57 anos por meio das lojas, site e APP.
            A empresa possui em todo o Brasil diversas lojas físicas, centros de distribuição 
            nas principais regiões do país e indústrias localizadas nas regiões sul, sudeste e 
            nordeste, sendo duas fábricas de câmaras, painéis e portas termoisolantes e uma fábrica 
            de suportes de ar-condicionado.`,
      },
      {
        title: 'A Agyx',
        content: `A AGYX é uma plataforma de soluções em serviços de Instalação de 
            Ar-Condicionado, que tem como missão atender as necessidades dos consumidores, 
            prestando o melhor serviço com a  maior e mais qualificada rede de profissionais 
            especializados do Brasil. A Agyx tem como objetivo entregar a solução ideal ao 
            cliente, com Qualidade, Segurança, Praticidade e Transparência em todos os 
            serviços prestados.`,
      },
      {
        title: 'O desafio',
        content: `A Roxcode desenvolveu o app que se tornou a maior plataforma especializada 
            em instalação de ar-condicionado do Brasil. O aplicativo é utilizado por milhares 
            de técnicos por todo o Brasil, por ele é realizado todo o processo de agendamento e 
            comprovação das instalações.
            Também criamos um portal administrativo robusto, que permite controle total da operação.`,
      },
    ],
  },
  urbia: {
    initialTexts: [
      { title: 'Segmento', content: 'Urbanismo' },
      {
        title: 'O que fizemos',
        content: 'Criação de plataforma web',
      },
    ],
    finalTexts: [
      {
        title: 'A Urbia',
        content: `A Urbia é uma startup inovadora que combina tecnologia e inteligência artificial para realizar estudos de viabilidade em terrenos para loteamentos. Com foco em oferecer soluções estratégicas a preços acessíveis, a Urbia atende clientes em todo o Brasil, ajudando-os a tomar decisões embasadas e eficientes.`,
      },
      {
        title: 'O desafio',
        content: `O Desafio
            A Urbia precisava de uma plataforma digital robusta que permitisse oferecer seus serviços de forma acessível e eficiente. A solução deveria conectar diversos serviços de informações territoriais, possibilitar a demarcação de terras pelos usuários e suportar funcionalidades avançadas, como importação de arquivos .KMZ e integração com sistemas de pagamento.`,
      },
      {
        title: 'Nossa solução',
        // @ts-ignore
        content: `
          <p>
            A Roxcode desenvolveu uma plataforma online que atende plenamente às necessidades da Urbia, entregando um serviço inovador e altamente funcional. Entre os destaques da solução estão:
          </p>
          <ul>
            <li><strong>Conexão com Serviços Territoriais:</strong> Acesso integrado a dados relevantes para estudos de terrenos.</li>
            <li><strong>Ferramenta de Demarcação:</strong> Usuários podem demarcar áreas de forma prática e precisa.</li>
            <li><strong>Suporte a Arquivos .KMZ:</strong> Importação direta de arquivos para maior flexibilidade na análise de dados.</li>
            <li><strong>Sistema de Pagamentos:</strong> Gestão de transações diretamente na plataforma.</li>
          </ul>
          <p>
            Essa solução foi projetada para ser rápida, intuitiva e confiável, melhorando significativamente a experiência dos clientes da Urbia e aumentando a satisfação com os serviços prestados.
          </p>
        `,
      },
    ],
  },
};
