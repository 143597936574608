export const casesTranslation = {
  downloadIn: 'Download in',
  cattalini: {
    initialTexts: [
      { title: 'Segment', content: 'Industry' },
      { title: 'What we did', content: 'Discovery, App development, and AI.' },
    ],
    finalTexts: [
      {
        title: 'Company',
        content:
          'Cattalini is the largest liquid terminal in Brazil, located in Paranaguá/PR. It has 133 tanks and a storage capacity of over 610,000 cubic meters of liquid bulk. The company is constantly seeking technology and safety advancements in bulk liquid services.',
      },
      {
        title: 'Challenge',
        content:
          'Roxcode is currently developing the third phase of the Cattalini app to facilitate access to essential information. The solution helps customers track their cargo, receive assistance, and schedule services. It also supports transport companies and drivers in the loading and unloading process, among many other features.',
      },
    ],
  },
  centerNorte: {
    initialTexts: [
      { title: 'Segment', content: 'Retail' },
      {
        title: 'What we did',
        content: 'App development and business intelligence',
      },
    ],
    finalTexts: [
      {
        title: 'The shopping mall',
        content: `Shopping Center Norte is an iconic destination in São Paulo’s North Zone and one of the region’s largest shopping and leisure hubs. Strategically located next to Marginal Tietê, it is part of Cidade Center Norte — a complex that includes shopping malls, a hotel, an event space, and Lar Center. Since its opening, Center Norte has continued to innovate to offer unique experiences to its visitors.`,
      },
      {
        title: 'The challenge',
        content: `Shopping Center Norte sought a technological solution to strengthen customer relationships, encourage purchases at participating stores, and reward customer loyalty. The challenge was to create a benefits club that was intuitive, dynamic, and aligned with the shopping mall's consumer profile.`,
      },
      {
        title: 'Our solution',
        // @ts-ignore
        content: `
          <p>
            Roxcode developed VIVA Center Norte, an exclusive app that serves as the shopping mall’s official benefits club. Key features include:
          </p>
          <ul>
            <li>
              <strong>Point System:</strong> Customers automatically earn points for every purchase made at participating stores.
            </li>
            <li>
              <strong>Points Redemption:</strong> Points can be exchanged for rewards, including exclusive discounts, gifts, and personalized experiences.
            </li>
            <li>
              <strong>Personalized Notifications:</strong> Real-time communication about promotions and events.
            </li>
            <li>
              <strong>Omnichannel Integration:</strong> Seamless connection between physical and digital shopping experiences.
            </li>
          </ul>
          <p>
            VIVA Center Norte was designed to enhance customer experience, increase engagement, and create new business opportunities for the shopping mall and its partner stores.
          </p>
        `,
      },
    ],
  },
  crm: {
    initialTexts: [
      { title: 'Segment', content: 'Retail' },
      {
        title: 'What we did',
        content: 'App development and technological intelligence',
      },
    ],
    finalTexts: [
      {
        title: 'About CRM&Bonus',
        content: `CRM&Bonus is an innovative platform that leverages data and artificial intelligence to transform business-customer relationships. Founded in 2018 with the launch of Giftback, the company quickly became a leader in customer retention and loyalty solutions. Today, CRM&Bonus offers a robust portfolio of tools, including Vale Bonus, CRM Ads, and CRM360, helping businesses of all sizes attract, engage, and retain consumers.
            With offices in São Paulo and Joinville, CRM&Bonus has a team of over 350 specialists and has raised R$ 280 million in venture capital from renowned investors such as SoftBank and Riverwood. In 2023 alone, the company generated over R$ 5 billion in incremental sales and rewarded more than 100 million consumers.`,
      },
      {
        title: 'Our partnership',
        content: `Roxcode is proud to be a strategic partner of CRM&Bonus, working closely on the evolution of the Vale Bonus app. Our team collaborates with CRM&Bonus' internal team to optimize app performance, enhance user experience, and integrate new features aligned with market demands.
            Through this partnership, the Vale Bonus app has doubled its active user base and significantly increased customer engagement in loyalty campaigns. These results highlight the impact of Roxcode’s technology and our commitment to excellence.`,
      },
    ],
  },
  agyx: {
    initialTexts: [
      { title: 'Segment', content: 'Retail and Services' },
      {
        title: 'What we did',
        content: 'App and back-office development',
      },
    ],
    finalTexts: [
      {
        title: 'Frigelar',
        content: `Frigelar is a market leader in Brazil in the refrigeration, air conditioning, and electronics sectors, providing complete solutions to customers for over 57 years through stores, its website, and an app.
            The company has a nationwide presence with physical stores, distribution centers in key regions, and factories in the South, Southeast, and Northeast. It operates two plants for thermal insulation panels, chambers, and doors, as well as a factory for air-conditioning supports.`,
      },
      {
        title: 'Agyx',
        content: `AGYX is a service platform specializing in air conditioning installation, committed to providing the best service through Brazil’s largest and most qualified network of specialized professionals. AGYX aims to deliver high-quality, safe, convenient, and transparent services to customers.`,
      },
      {
        title: 'The challenge',
        content: `Roxcode developed an app that became Brazil’s largest specialized air-conditioning installation platform. The app is used by thousands of technicians across the country, managing scheduling and installation verification processes.
            Additionally, we created a robust administrative portal that provides complete operational control.`,
      },
    ],
  },
  urbia: {
    initialTexts: [
      { title: 'Segment', content: 'Urbanism' },
      {
        title: 'What we did',
        content: 'Web platform development',
      },
    ],
    finalTexts: [
      {
        title: 'About Urbia',
        content: `Urbia is an innovative startup that combines technology and artificial intelligence to conduct feasibility studies for land development. Focused on offering strategic solutions at accessible prices, Urbia serves clients throughout Brazil, helping them make informed and efficient decisions.`,
      },
      {
        title: 'The challenge',
        content: `Urbia needed a robust digital platform to deliver its services efficiently and affordably. The solution had to integrate various territorial data services, allow users to mark land plots, and support advanced functionalities such as .KMZ file import and payment system integration.`,
      },
      {
        title: 'Our solution',
        // @ts-ignore
        content: `
          <p>
            Roxcode developed an online platform that fully meets Urbia’s needs, delivering an innovative and highly functional service. Key features include:
          </p>
          <ul>
            <li><strong>Integration with Territorial Data Services:</strong> Seamless access to relevant land study data.</li>
            <li><strong>Land Plotting Tool:</strong> Users can mark land areas easily and accurately.</li>
            <li><strong>.KMZ File Support:</strong> Direct import of files for greater flexibility in data analysis.</li>
            <li><strong>Payment System:</strong> Direct transaction management within the platform.</li>
          </ul>
          <p>
            This solution was designed to be fast, intuitive, and reliable, significantly improving Urbia’s customer experience and overall satisfaction.
          </p>
        `,
      },
    ],
  },
};
