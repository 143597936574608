import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Params {
  from: number;
  to: number;
}

export const useContactUsAction = () => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation('header');

  const valuesFromHover = useCallback(
    ({ from, to }: Params) => (isHovered ? from : to),
    [isHovered]
  );

  return {
    isHovered,
    setIsHovered,
    navigate,
    t,
    valuesFromHover,
  };
};
