import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { logCustomEvent } from 'src/utils/analytics';

export const useLanguageMenu = () => {
  const [isLangHovered, setIsLangHovered] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { t, i18n } = useTranslation('header');

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsLangHovered(true);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setIsLangHovered(false);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    handleMenuClose();
    logCustomEvent(`switch_lang_to_${lang.replace(/-/g, '_')}`, {
      label: `Switch Language to ${lang}`,
      description: `Switch Language to ${lang} by clicking on the header language menu`,
    });
  };

  const languages = [
    { code: 'pt-BR', label: t('language_menu.portuguese'), flag: 'twemoji:flag-for-brazil' },
    { code: 'en', label: t('language_menu.english'), flag: 'twemoji:flag-for-united-states' },
  ];

  return {
    isLangHovered,
    setIsLangHovered,
    anchorEl,
    open,
    t,
    i18n,
    handleMenuOpen,
    handleMenuClose,
    changeLanguage,
    languages,
  };
};
