import { useEffect, useRef, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import Router from 'src/routes/sections';

import { useScrollToTop } from 'src/hooks/use-scroll-to-top';

import 'src/global.css';
import 'src/locales/i18n';
import ThemeProvider from 'src/theme';

import ProgressBar from 'src/components/progress-bar';
import { SettingsProvider } from 'src/components/settings';
import { MotionLazy } from 'src/components/animate/motion-lazy';
import { initializeApp } from 'firebase/app';
import { getAnalytics, setAnalyticsCollectionEnabled } from 'firebase/analytics';

import { useLocation } from 'react-router-dom';
import { useAuth } from './auth/auth.hook';
import { LocalizationProvider } from './locales';
import { SnackbarProvider } from './components/snackbar';
import { analytics, logPageView } from './utils/analytics';

export default function App() {
  const location = useLocation();
  const startTime = useRef<number | null>(null);
  const prevPathname = useRef<string | null>(null);

  useEffect(() => {
    const isNotHttps = window.location.protocol !== 'https:';
    const isLocalhost = window.location.hostname === 'localhost';

    if (isNotHttps && !isLocalhost) {
      const httpsUrl = `https://${window.location.hostname}${window.location.pathname}${window.location.search}`;
      window.location.replace(httpsUrl); // Força o redirecionamento para HTTPS
    }
  }, []);
  
  useEffect(() => {
    if (startTime.current !== null && prevPathname.current !== null) {
      const durationTime = (Date.now() - startTime.current) / 1000; // Em segundos
      logPageView(prevPathname.current, {
        description: `Time spent on ${prevPathname.current} page`,
        duration: durationTime,
      });
    }

    startTime.current = Date.now(); // Reinicia a contagem de tempo ao trocar de página
    prevPathname.current = location.pathname; // Salva o pathname atual para o registrar na próxima troca de página
  }, [location.pathname]);

  useEffect(() => {
    startTime.current = Date.now(); // Inicia a contagem de tempo ao carregar a página pela primeira vez
    prevPathname.current = location.pathname; // Salva o pathname atual para o registro do primeiro log

    const handleBeforeUnload = () => {
      if (startTime.current !== null && prevPathname.current !== null) {
        const durationTime = (Date.now() - startTime.current) / 1000;
        try {
          logPageView(prevPathname.current, {
            description: `Time spent on ${prevPathname.current} page`,
            duration: durationTime,
          });
        } catch (error) {
          console.error('Erro ao registrar o último log antes de sair:', error);
        }
      }
    };

    // Escuta o evento de sair da página para enviar o log de duração da última página visitada
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <LocalizationProvider>
      <SettingsProvider
        defaultSettings={{
          themeMode: 'dark', // 'light' | 'dark'
          themeDirection: 'ltr', //  'rtl' | 'ltr'
          themeContrast: 'default', // 'default' | 'bold'
          themeLayout: 'horizontal', // 'vertical' | 'horizontal' | 'mini'
          themeColorPresets: 'default', // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
          themeStretch: false,
        }}
      >
        <ThemeProvider>
          <MotionLazy>
            <SnackbarProvider>
              <ProgressBar />
              <Router />
            </SnackbarProvider>
          </MotionLazy>
        </ThemeProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}
