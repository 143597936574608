export const formTranslation = {
  titles: {
    create_company: 'Criando sua Empresa',
    create_job: 'Nova Vaga',
    company_data: 'Dados da Empresa',
    contacts: 'Contatos',
    localization: 'Localização',
    tips: 'Dicas',
    details: 'Detalhes',
    properties: 'Propriedades',
    job_questions: 'Perguntas da Vaga',
    job_filters: 'Filtros da Vaga',
    create_professional_profile: 'Criando seu Perfil Profissional',
    edit_professional_profile: 'Editando seu perfil Profissional',
    personal_data: 'Dados Pessoais',
    professional_data: 'Dados Profissionais',
    preferences: 'Preferências',
    schedule: 'Horário de Trabalho',
    salary: 'Salário',
    salary_negotiable: 'Salário negociável',
    benefits: 'Benefícios',
  },
  fields: {
    select: 'selecione',
    email: 'E-mail',
    password: 'Senha',
    confirm_password: 'Confirmar Senha',
    name: 'Nome',
    lastname: 'Sobrenome',
    company_name: 'Razão social',
    trading_name: 'Nome fantasia',
    about: 'Sobre',
    history: 'História',
    segment: 'Segmento',
    employees: 'Funcionários',
    phone: 'Telefone',
    website: 'Site',
    country: 'País',
    estate: 'Estado',
    city: 'Cidade',
    title: 'Título',
    description: 'Descrição',
    type: 'Tipo',
    experience: 'Experiência',
    role: 'Função',
    cpf: 'CPF',
    curriculum: 'Currículo',
    experiences: 'Experiências',
    interest_area: 'Área de interesse',
    skills: 'Competências',
    tech_skills: 'Competências Técnicas',
    behavior_skills: 'Competências Comportamentais',
    presential: 'Presencial',
    presential_days: 'Dias presencial',
    hybrid: 'Híbrido',
    remote: 'Remoto',
    work_regime: 'Regime de Trabalho',
    full_time: 'Tempo Integral',
    part_time: 'Meio Período',
    freelancer: 'Freelancer',
    trainee: 'Estagiário',
    no_experience: 'Sem experiência',
    '1_year_exp': '1 ano',
    '2_year_exp': '2 anos',
    less_3_year_exp: 'mais de 3 anos',
    not_applicable: 'Não se aplica',
    black_people: 'Pessoas pretas',
    women: 'Mulheres',
    pcd: 'PCD',
    lgbtqiap: 'LGBTQIAP+',
    indigenous: 'Indígenas',
    job_affirmative: 'Vaga inclusiva',
    job_limit: 'Limite de candidatos',
    interview_steps: 'Etapas de entrevista necessárias',
    start_date: 'Data Inicial',
    end_date: 'Data Final',
    job_allow_questions: 'Permitir que os candidatos façam perguntas',
    job_allow_less_skills:
      'Permitir que os candidatos não possuam 100% das habilidades necessárias',
  },
  actions: {
    save: 'Salvar',
    next: 'Próximo',
    enter: 'Entrar',
    register: 'Criar conta',
    publish: 'Publicar',
    add_question: 'Adicionar pergunta',
    add: 'Adicionar',
    download_file: 'Download do arquivo',
  },
  messages: {
    required: '{{field}} é obrigatório',
    forgot_password: 'Esqueceu sua senha?',
    email_valid: 'E-mail precisa ter um endereço de e-mail válido',
    choose_at_least_one_option: 'Selecione ao menos uma opção',
    phone_valid: 'Telefone incompleto',
  },
  errors: {
    invalid_data: 'Dados inválidos',
    auth_unknown: 'Não foi possível autenticar. Verifique seus dados ou entre em contato',
    unknonw_error: 'Erro desconhecido',
  },
  contact_us: {
    title: 'FALE CONOSCO',
    name: 'Nome',
    email: 'E-mail',
    more_info: 'Mais informações',
    send: 'Enviar',
    name_error: 'Digite o nome.',
    email_error: 'Digite o e-mail.',
    email_valid_error: 'Digite um e-mail válido.',
    more_info_error: 'Digite mais informações de contato.',
  },
};
