import React from 'react';
import { Box } from '@mui/material';
import { m } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { useScrollDirection } from 'src/hooks/use-scroll';
import { logCustomEvent } from 'src/utils/analytics';
import Logo from '../logo';
import Actions from '../actions/actions';

export interface HeaderProps {
  showContact?: boolean;
}

interface AnimatedProps {
  children: JSX.Element;
}

function AnimationOnScrollY({ children }: AnimatedProps) {
  const scrollDirection = useScrollDirection();

  return (
    <m.div
      initial={{ opacity: 1, y: 0 }}
      animate={{
        opacity: scrollDirection === 'up' ? 1 : 0,
        y: scrollDirection === 'up' ? 0 : -100,
      }}
      transition={{ duration: 1 }}
      style={{ position: 'fixed', top: 0, width: '100%' }}
    >
      {children}
    </m.div>
  );
}

export default function Header({ showContact = true }: HeaderProps) {
  const navigate = useNavigate();

  if (!showContact) {
    return (
      <Box
        data-testid="header-render-test"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 3,
          position: 'fixed',
          top: 10,
          zIndex: 100000,
          width: '100%',
        }}
      >
        <Box flexGrow={1} />
        <Logo
          url="/home"
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: 100000,
            marginInline: 'auto',
            width: 'clamp(100px, 7vw, 200px)',
            height: 'auto',
            ':hover': {
              cursor: 'pointer',
            },
            alignSelf: 'center',
          }}
          onClick={() => {
            navigate('/home');
            logCustomEvent(`header_logo`, {
              label: `Header Logo Navigate`,
              description: `Clickable logo in header, that navigates to /home`,
            });
          }}
        />
        <Actions showContact={showContact} />
      </Box>
    );
  }

  return (
    <AnimationOnScrollY>
      <Box
        data-testid="header-render-test"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 3,
          position: 'fixed',
          top: 10,
          zIndex: 100000,
          width: '100%',
        }}
      >
        <Box flexGrow={1} />
        <Logo
          url="/home"
          sx={{
            position: 'absolute',
            left: 0,
            right: 0,
            zIndex: 100000,
            marginInline: 'auto',
            width: 'clamp(100px, 7vw, 200px)',
            height: 'auto',
            ':hover': {
              cursor: 'pointer',
            },
            alignSelf: 'center',
          }}
          onClick={() => {
            navigate('/home');
          }}
        />
        <Actions showContact={showContact} />
      </Box>
    </AnimationOnScrollY>
  );
}
