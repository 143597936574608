import { Stack } from '@mui/material';
import LanguageMenu from 'src/components/language-menu/language-menu';
import ContactUsAction from 'src/components/contact-us-action/contact-us-action';

interface ActionsProps {
  showContact?: boolean;
}

export default function Actions({ showContact }: ActionsProps) {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <LanguageMenu />
      {showContact && <ContactUsAction />}
    </Stack>
  );
}
