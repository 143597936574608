export const homeTranslation = {
  title: 'CRIE. TRANSFORME.',
  description:
    'Nascemos para criar. Quando criamos, transformamos; e ao transformar, enxergamos o mundo sob novas perspectivas. Desde coisas simples até as mais complexas; de ideias a edificações, de sonhos a realidades.',
  subdescription: "Legal! Mas o que é feito na Rox...",
  cases: {
    cattalini: 'Maior terminal de líquidos do Brasil',
    cn: 'Um dos maiores shoppings da América Latina',
    crm: 'Programa de recompensas e compras',
    agyx: 'Prestador de serviços de climatização',
    urbia: 'Estudos de viabilidade territorial',
  }
};
