import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { translations } from './langs';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: 'en', // Se não for pt ou pt-BR, usa inglês
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['sessionStorage', 'localStorage', 'navigator'], // Primeiro verifica o sessionStorage, depois o localStorage e, por fim, o navegador
      caches: ['sessionStorage'], // Salva a escolha no sessionStorage
    },
  });

// 🔹 Obtém o idioma salvo na sessão, se existir
const storedLang = sessionStorage.getItem('i18nextLng');

// 🔹 Obtém apenas o idioma principal do navegador
const browserLang = navigator.language || 'en';

// 🔹 Define o idioma final: se o usuário mudou manualmente, usa esse
const selectedLang = storedLang || (browserLang.startsWith('pt') ? 'pt-BR' : 'en');

// 🔹 Aplica o idioma ao i18n
i18n.changeLanguage(selectedLang);

// 🔹 Salva no sessionStorage sempre que o idioma for alterado manualmente
i18n.on('languageChanged', (lng) => {
  sessionStorage.setItem('i18nextLng', lng);
});

export default i18n;
